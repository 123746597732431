/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    strong: "strong",
    a: "a",
    hr: "hr",
    h2: "h2",
    div: "div",
    p: "p",
    h3: "h3",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "GitHub:"), " ", React.createElement(_components.a, {
    href: "https://github.com/geyokoyama"
  }, "geyokoyama")), "\n", React.createElement(_components.li, null, React.createElement(_components.strong, null, "LinkedIn:"), " ", React.createElement(_components.a, {
    href: "https://www.linkedin.com/in/georgeyokoyama/"
  }, "georgeyokoyama")), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h2, {
    id: "summary",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#summary",
    "aria-label": "summary permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Summary"), "\n", React.createElement(_components.p, null, "Web applications developer with 1+ years of coding, testing, and deploying features for Ruby on Rails applications and working on static website projects. Seeking an opportunity to become part of an engineering team working on SaaS."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h2, {
    id: "experience",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#experience",
    "aria-label": "experience permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Experience"), "\n", React.createElement(_components.h3, {
    id: "line--prep-cook",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#line--prep-cook",
    "aria-label": "line  prep cook permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Line & Prep Cook"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Panera Bread"), " — ", React.createElement(_components.em, null, "January 2020 - Present"), " — Dublin, CA"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Cleaned and prepared vegetables and meats for use by line cooks"), "\n", React.createElement(_components.li, null, "Assisted line cooks during rush hour when busy"), "\n", React.createElement(_components.li, null, "Maintained a clean work area by washing, drying, and sanitizing tools and surfaces"), "\n", React.createElement(_components.li, null, "Prepared menu items during breakfast and lunch according to restaurant standards"), "\n", React.createElement(_components.li, null, "Put away produce, frozen ingredients, and equipment delivered to the cafe"), "\n"), "\n", React.createElement(_components.h3, {
    id: "lead-web-developer",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#lead-web-developer",
    "aria-label": "lead web developer permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Lead Web Developer"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "RubyThree LLC"), " — ", React.createElement(_components.em, null, "August 2019 - September 2019"), " — Dublin, CA"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Supervised the team and helped keep them on track and on time"), "\n", React.createElement(_components.li, null, "Assisted junior members of the team with learning, debugging, and completing their tasks"), "\n", React.createElement(_components.li, null, "Researched technologies and software packages for use in web applications"), "\n"), "\n", React.createElement(_components.h3, {
    id: "junior-web-applications-developer",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#junior-web-applications-developer",
    "aria-label": "junior web applications developer permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Junior Web Applications Developer"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "RubyThree LLC"), " — ", React.createElement(_components.em, null, "August 2018 - August 2019"), " — Dublin, CA"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Integrated front-end UIs with server-side logic"), "\n", React.createElement(_components.li, null, "Collaborated with co-workers and product managers to develop and maintain applications"), "\n", React.createElement(_components.li, null, "Implemented 3rd party APIs such as Twilio, Stripe, and eBay"), "\n"), "\n", React.createElement(_components.h3, {
    id: "line-cook",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#line-cook",
    "aria-label": "line cook permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Line Cook"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Panera Bread"), " — ", React.createElement(_components.em, null, "June 2017 - June 2018"), " — Foster City, CA"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Helped train new kitchen staff"), "\n", React.createElement(_components.li, null, "Made sandwiches and salads quickly"), "\n", React.createElement(_components.li, null, "Cleaned the kitchen"), "\n"), "\n", React.createElement(_components.h3, {
    id: "intern",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#intern",
    "aria-label": "intern permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Intern"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Tixit"), " — ", React.createElement(_components.em, null, "July 2017 - January 2018"), " — Remote Work"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Created Tixit-md, a Tixit plugin for a better writing experience"), "\n", React.createElement(_components.li, null, "Assisted other interns who were developing plugins for Tixit"), "\n", React.createElement(_components.li, null, "Conducted manual QA testing and provided details on bugs and user experience"), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h2, {
    id: "skills",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#skills",
    "aria-label": "skills permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Skills"), "\n", React.createElement(_components.h3, {
    id: "languages",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#languages",
    "aria-label": "languages permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Languages"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "HTML5"), "\n", React.createElement(_components.li, null, "CSS3"), "\n", React.createElement(_components.li, null, "JSON"), "\n", React.createElement(_components.li, null, "Bash/Zsh"), "\n", React.createElement(_components.li, null, "JavaScript"), "\n", React.createElement(_components.li, null, "Ruby"), "\n"), "\n", React.createElement(_components.h3, {
    id: "tools",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#tools",
    "aria-label": "tools permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Tools"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.gatsbyjs.com/"
  }, "Gatsby")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://rubyonrails.org/"
  }, "Ruby on Rails")), "\n", React.createElement(_components.li, null, "Git/GitHub"), "\n", React.createElement(_components.li, null, "Testing (", React.createElement(_components.a, {
    href: "https://rspec.info/"
  }, "Rspec"), ")"), "\n", React.createElement(_components.li, null, "Authentication (", React.createElement(_components.a, {
    href: "https://github.com/heartcombo/devise"
  }, "Devise"), ")"), "\n"), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h2, {
    id: "education",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#education",
    "aria-label": "education permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Education"), "\n", React.createElement(_components.h3, {
    id: "bs-in-cognitive-science",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#bs-in-cognitive-science",
    "aria-label": "bs in cognitive science permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "B.S. in Cognitive Science"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "University of California, Santa Cruz"), " — 2014 - 2016"), "\n", React.createElement(_components.h3, {
    id: "aa-in-liberal-arts",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#aa-in-liberal-arts",
    "aria-label": "aa in liberal arts permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "A.A. in Liberal Arts"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "De Anza College"), " — 2011 - 2014"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
