import GATSBY_COMPILED_MDX from "/opt/build/repo/content/pages/resume.mdx";
import * as React from "react";
import {graphql} from "gatsby";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleUser} from '@fortawesome/free-solid-svg-icons';
import {MDXProvider} from "@mdx-js/react";
import MDXComponents from "../components/MDXComponents";
import Seo from "../components/SEO";
const Page = ({data: {mdx}, children}) => {
  const page = mdx;
  return React.createElement("article", null, React.createElement("header", {
    className: "text-teal-50 bg-gradient-to-tr from-teal-900 to-teal-500"
  }, React.createElement("div", {
    className: "px-3 py-10 max-w-screen-xl m-auto relative"
  }, React.createElement("div", {
    className: "z-20 relative"
  }, React.createElement("span", {
    className: "absolute top-[-1.2rem] md:top-0 right-0 text-9xl text-teal-500 opacity-50 drop-shadow-md"
  }, React.createElement(FontAwesomeIcon, {
    icon: faCircleUser
  })), React.createElement("h1", {
    className: "text-3xl md:text-6xl font-semibold pb-5 md:pb-8"
  }, page.frontmatter.title), page.frontmatter.date && React.createElement("p", {
    className: "text-xl md:text-3xl pb-3 md:pb-6"
  }, "Updated on ", page.frontmatter.date)))), React.createElement("div", {
    className: "max-w-screen-xl m-auto"
  }, React.createElement("div", {
    className: "px-3 py-10 max-w-prose m-auto md:text-xl"
  }, React.createElement(MDXProvider, {
    components: MDXComponents
  }, children))));
};
export const query = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      frontmatter {
        date
        title
      }
    }
  }
`;
Page
export default function GatsbyMDXWrapper(props) {
  return React.createElement(Page, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const Head = ({data: {mdx: {frontmatter: {title}}}}) => React.createElement(Seo, {
  pageTitle: title
});
